import { ProjectType } from '../../../types';

//  const Projectcopy = {
//   title: '',
//   imgSrc: '',
//   description: '',
//   githubLink: ''
// };
//  const otherProjectCopy = {
//   imgSrc: '',
//   githubLink: '',
//   deployedLink: ''
// };

export const brandlive = {
  title: 'Brandlive - Events/Streams/Platform',
  videoSrc: 'https://www.brandlive.com/wp-content/uploads/Events-iPad-v2.mp4',
  description:
    'Built, released, and maintained the 3.0 version of the Events product (also known as Streams and The Platform) starting with a team of 6 devs that grew to 18. The application is focused on virtual events, meetings, and webinars for enterprise clients.',
  githubLink: '#',
  mediaCaption:
    'Product demo curtesy of <a href="https://www.brandlive.com/events" target="_blank">Brandlive website</a>',
};

export const estateSaleTracker = {
  title: 'Estate Sale Tracker',
  imgSrc:
    'https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExNzIzMzE1Nzk1MmM5OTJkNDVmMDM1ZjAzMWFmY2Q5MWE2MDQwMzQ2NCZlcD12MV9pbnRlcm5hbF9naWZzX2dpZklkJmN0PWc/PsTXiqttbb8MGMJ8fO/giphy.gif',
  description: `This app is a solo project designed to help mobile users find and track estate sales in the Portland (PDX) area. It leverages the Next.JS framework (written in TypeScript) to provide an API and a React-based front end. The data is collected from various sources and reorganized via a web-scraper that uses JSDom. Users can sign in using Google SSO, which is handled by the NextAuth library, and track estate sales they are interested in by saving them to a Postgres database. The app also uses a cron job to periodically send email notifications to users about upcoming sales, which is facilitated through the MailJet API.
  <br />
  One of the key features of this app is its use of the Google Maps API, which is integrated with custom markers and clusters to display the locations of estate sales. The app also includes a virtual list that enhances performance by rendering only the items that are currently visible on the screen, rather than loading all items at once. This makes the app more responsive and improves the user experience.`,
  githubLink: 'https://github.com/benwaples/estate-sale-trackr-nextjs',
  mediaCaption:
    'Try it out yourself! <a href="https://estate-sale-trackr-nextjs.vercel.app/map" target="_blank" rel="noreferrer">Demo here</a>',
};

export const canary = {
  title: 'Canary',
  imgSrc: 'https://i.ibb.co/0GQczhS/4nlffj.gif',
  description: `Built during a 1-week remote sprint, Canary mimic's the classroom environment by using TensorFlow MobileNet machine learning to analyze the user's gestures and answer real-time questions coming from a teacher. The front-end is a React app with Redux and React Context to manage global state, the TensorFlow MobileNet model with a KNN Classifier for calibrating and playing, and features libraries such as React-Router-Dom, React-Countdown, and Socket.io. The backend is built in Nodejs with an Express API, PostgreSQL database, Socket.io server, and Bcrypt password hashing.`,
  githubLink: 'https://github.com/The-Canary-Project',
};

export const alchemyNetworkr = {
  title: 'Alchemy Networkr',
  imgSrc: 'https://media.giphy.com/media/R5fyBMahOc7RqqeyOA/giphy.gif',
  description:
    'Alchemy Networkr, built in a 1 week remote sprint, is a social network for code school students to find and collaborate on projects. Built in Node.js with an Express API, Postgres Database, and Pug.js server side rendering. This app features the Github API, Jest testing framework, and integrated SSO.',
  githubLink: 'https://github.com/Alchemy-Networkr/Networkr-BE/tree/main',
};

export const nsnb = {
  title: 'No Stars, No Bucks',
  imgSrc: 'https://media.giphy.com/media/B9QVsOvIGNgyg6pnJ5/giphy.gif',
  description: `No Stars, No Bucks is a React App front-end with a Express API and postgreSQL database used by coffee enthusiasts who are traveling and looking to find the best coffee shops that aren't a chain. RESTful methodologies were used when building the backend API in order to streamline future development.`,
  githubLink: 'https://github.com/benwaples/coffee-findr-fe',
};

const projects: ProjectType[] = [
  brandlive,
  estateSaleTracker,
  canary,
  alchemyNetworkr,
  nsnb,
];

export default projects;

export const nasaTracker = {
  imgSrc: 'https://media.giphy.com/media/Ro0zscHaN7jyG7giy6/giphy.gif',
  githubLink: 'https://github.com/benwaples/favorite-api-fe',
  deployedLink: 'tender-easley-235cc8.netlify.app/',
};

export const plantr = {
  imgSrc: 'https://media.giphy.com/media/k68bJOJgakYWvtYao5/giphy.gif',
  githubLink: 'https://github.com/benwaples/plantr',
  deployedLink: 'https://benwaples.github.io/plantr/index.html',
};

export const twelveDays = {
  imgSrc: 'https://media.giphy.com/media/C1dfX58KSYBPGU4Rzj/giphy.gif',
  githubLink: 'https://github.com/benwaples/12days-fe',
  deployedLink: 'https://12-days-of-christmas.netlify.app/',
};

export const otherProjects = [plantr, twelveDays, nasaTracker];
